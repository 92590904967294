import React from 'react';
import css from './wrap.module.css';
import cn from 'classnames';

function Wrap({ children, menuActive }) {
  return (
    <div className={cn(css.wrap, { [css.menuActive]: menuActive })}>
      {children}
    </div>
  );
}

export default Wrap;
