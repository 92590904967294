import React from 'react';
import { Link } from 'gatsby';
import css from './header.module.css';
import cn from 'classnames';
import logo from './images/logo.svg';

import Menu from '../Menu';
import Hamburger from '../Hamburger';

function Header({ menuActive, setMenuActive }) {
  return (
    <header className={cn(css.header, { [css.menuActive]: menuActive })}>
      <div className={css.container}>
        <Link className={css.logoLink} to={'/'}>
          <img className={css.logo} src={logo} alt="Eli Horowitz" />
        </Link>

        <Menu setMenuActive={setMenuActive} />

        <Hamburger onClick={() => setMenuActive(!menuActive)} />
      </div>
    </header>
  );
}

export default Header;
