import React from 'react';
import { Link } from 'gatsby';
import css from './footer.module.css';
import cn from 'classnames';
import logo from './images/logo-light.svg';

import Icons from '../Icons';

const navItems = [
  {
    text: 'About',
    url: '/about/'
  },
  {
    text: 'Nonfiction',
    url: '/nonfiction/'
  },
  {
    text: 'Fiction',
    url: '/fiction/'
  },
  {
    text: 'Contact',
    url: '/contact/'
  },
  {
    text: 'BODIED',
    url: '/bodied/'
  }
];

function Footer() {
  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <div className={css.body}>
          <img src={logo} className={css.logo} alt="Eli Horowitz" />

          <nav className={css.nav}>
            {navItems &&
              navItems.map(({ text, url }) => (
                <Link to={url} key={url + text} className={css.navItem}>
                  {text}
                </Link>
              ))}
          </nav>

          <Icons className={css.icons} theme={'gray'} />
        </div>

        <div className={css.bottom}>
          <p className={cn(css.copy, css.legal)}>
            <Link to={'/terms-of-service/'} className={css.legalLink}>
              Terms of Service
            </Link>

            <Link to={'/privacy-policy/'} className={css.legalLink}>
              Privacy Policy
            </Link>
          </p>

          <p className={cn(css.copy, css.credit)}>
            &copy; {new Date().getFullYear()} Elijah Horowitz{' '}
            <span className={css.separator}>&nbsp;&bull;&nbsp;</span> All rights
            reserved
            <span className={css.separator}>&nbsp;&bull;&nbsp;</span>
            <a
              href="https://arteagabriel.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website by Gabriel Arteaga
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
