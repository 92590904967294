import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import css from './icons.module.css';
import cn from 'classnames';

import facebookPrimary from './images/icon-facebook-primary.svg';
import twitchPrimary from './images/icon-twitch-primary.svg';
import twitterPrimary from './images/icon-twitter-primary.svg';
import linkedinPrimary from './images/icon-linkedin-primary.svg';
import emailPrimary from './images/icon-email-primary.svg';
import facebookGray from './images/icon-facebook-gray.svg';
import twitchGray from './images/icon-twitch-gray.svg';
import twitterGray from './images/icon-twitter-gray.svg';
import linkedinGray from './images/icon-linkedin-gray.svg';
import emailGray from './images/icon-email-gray.svg';

function Icons({ className, theme = 'primary' }) {
  const data = useStaticQuery(graphql`
    query {
      site: allContentfulSite(limit: 1) {
        edges {
          node {
            facebookUrl
            email
            linkedinUrl
            twitterUrl
            twitchUrl
          }
        }
      }
    }
  `);
  const {
    facebookUrl,
    linkedinUrl,
    twitterUrl,
    twitchUrl,
    email
  } = data.site.edges[0].node;

  let facebookIcon;
  let twitterIcon;
  let linkedinIcon;
  let emailIcon;
  let twitchIcon;

  if (theme === 'primary') {
    facebookIcon = facebookPrimary;
    twitterIcon = twitterPrimary;
    linkedinIcon = linkedinPrimary;
    emailIcon = emailPrimary;
    twitchIcon = twitchPrimary;
  } else {
    facebookIcon = facebookGray;
    twitterIcon = twitterGray;
    linkedinIcon = linkedinGray;
    emailIcon = emailGray;
    twitchIcon = twitchGray;
  }

  return (
    <div className={cn(className, css.icons)}>
      {email && email.length && (
        <a href={'mailto:' + email} className={css.iconLink}>
          <img src={emailIcon} alt="Email Me" className={css.icon} />
        </a>
      )}

      {linkedinUrl && linkedinUrl.length && (
        <a
          href={linkedinUrl}
          className={css.iconLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={linkedinIcon}
            alt="Follow on LinkedIn"
            className={css.icon}
          />
        </a>
      )}

      {twitchUrl && twitchUrl.length && (
        <a
          href={twitchUrl}
          className={css.iconLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={twitchIcon}
            alt="Follow on Twitch.TV"
            className={css.icon}
          />
        </a>
      )}

      {facebookUrl && facebookUrl.length && (
        <a
          href={facebookUrl}
          className={css.iconLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={facebookIcon}
            alt="Follow on Facebook"
            className={css.icon}
          />
        </a>
      )}

      {twitterUrl && twitterUrl.length && (
        <a
          href={twitterUrl}
          className={css.iconLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitterIcon} alt="Follow on Twitter" className={css.icon} />
        </a>
      )}
    </div>
  );
}

export default Icons;
