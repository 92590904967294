import React from 'react';
import css from './hamburger.module.css';

function Hamburger({ onClick }) {
  return (
    <button className={css.hamburger} aria-label="Open Menu" onClick={onClick}>
      <div className={css.line} />
      <div className={css.line} />
      <div className={css.line} />
    </button>
  );
}

export default Hamburger;
