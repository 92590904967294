import React from 'react';
import css from './menu.module.css';

import Nav from '../Nav';
import Icons from '../Icons';

function Menu({ setMenuActive }) {
  return (
    <div className={css.menu}>
      <Nav setMenuActive={setMenuActive} />

      <Icons className={css.icons} />

      <button
        className={css.close}
        aria-label="Close Menu"
        onClick={() => setMenuActive(false)}
      >
        <div className={css.closeLine} />
        <div className={css.closeLine} />
      </button>
    </div>
  );
}

export default Menu;
