import React from 'react';
import { Link } from 'gatsby';
import css from './nav.module.css';
import cn from 'classnames';

const navItems = [
  {
    text: 'About',
    url: '/about/'
  },
  {
    text: 'Nonfiction',
    url: '/nonfiction/'
  },
  {
    text: 'Fiction',
    url: '/fiction/'
  },
  {
    text: 'Contact',
    url: '/contact/'
  },
  {
    text: 'BODIED',
    url: '/bodied/'
  },
  {
    text: 'Blog',
    url: '/blog/'
  }
];

function Nav({ className, setMenuActive }) {
  return (
    <nav className={cn(className, css.nav)}>
      {navItems &&
        navItems.map(({ text, url }) => (
          <Link
            to={url}
            key={url + text}
            className={css.navItem}
            onClick={() => setMenuActive(false)}
          >
            {text}
          </Link>
        ))}
    </nav>
  );
}

export default Nav;
