import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './layout.css';

import Header from '../Header';
import Footer from '../Footer';
import Wrap from '../Wrap';

function Layout({ children }) {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900,900i|Roboto:400,400i,500,500i&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Header menuActive={menuActive} setMenuActive={setMenuActive} />

      <Wrap menuActive={menuActive}>
        <main>{children}</main>

        <Footer />
      </Wrap>
    </>
  );
}

export default Layout;
