// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-post-js": () => import("./../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bodied-js": () => import("./../src/pages/bodied.js" /* webpackChunkName: "component---src-pages-bodied-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fiction-js": () => import("./../src/pages/fiction.js" /* webpackChunkName: "component---src-pages-fiction-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nonfiction-js": () => import("./../src/pages/nonfiction.js" /* webpackChunkName: "component---src-pages-nonfiction-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

